footer.main_footer {
  width: 100%;
  float: left;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: $white;
  padding: 16px 15px;
  border-top: 1px solid $brand-primary;
  .link_list_group {
    text-align: center;
  }
  .footer_link {
    margin-right: 36px;
    font-size: 14px;
    color: $brand-secondary;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
  }
  a{
    color: $white;
    &:hover,&:focus,&:active{
      color: $brand-primary;

    }
  }
}