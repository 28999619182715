.export_form{
    width: 100%;
    display: block;
    margin-bottom: 10px;
}
.export_form .custom_btn{
    display: inline-block;
    margin-top: 30px;
    height: 52px;
}
.export_sec{
    width: 100%;
    display: block;
}

.export_data_date_picker {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin: 0 !important;
    padding-top: 5px !important;
    border: 1px solid #EBEBEB !important;
}

.export_data_date_picker.error{
    border-color: rgb(240, 77, 83) !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
}

.export_data_date_picker .MuiInput-underline:before{
    border: 0 !important;
}

.export_data_date_picker .MuiInput-underline{
    border: 0 !important;
    outline: 0 !important;
}

.export_data_date_picker .MuiIconButton-root{
    padding: 0 !important;
    margin-top: 0px !important;
}

.export_data_date_picker .MuiInput-underline:hover:not(.Mui-disabled):before{
    border: 0 !important;
}

.export_data_date_picker .MuiInput-underline:before{
    border: 0 !important;
}

.export_data_date_picker .MuiInput-underline:after{
    border: 0 !important;
}

.export_data_date_picker .MuiInputBase-input{
    padding: 12px 0 7px !important;
}

.export_sec.wrap-login100{
    width: 100%;
    margin: 0;
    padding: 15px;
}

@media only screen and (max-width: 760px) {
    .export_form .custom_btn {
     margin-top: 15px;
    }
  }

  #export_data_download{
      display: none;
  }