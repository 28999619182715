.spinner_loading{
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    color: #16449A;
    transform: translate(-50%, -50%);
    position: absolute !important;
}

.spinner_loading.ht_30{
    width: 30px;
    height: 30px;
}