/* Media query */
@media screen and ( min-width: 1200px) {
    .vertical_tabs_cont {
        max-width: 90%;
    }
}
@media screen and ( min-width: 992px) {
    .header__logo {
        height: 40px;
    }
    .navigation-toggler {
        display: none;
    }
    .theme_navigation.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }
    header.main_header ul .nav-item .nav-link {
        margin-left: 2.5rem;
    }
    .nav_img_right {
        position: relative;
    }
    .nav_img_right .nav-item.ims_admin {
        position: absolute;
        right: 192px;
    }
    .nav_img_right .nav-item.nav_item_Slast {
        position: absolute;
        right: 124px;
    }
    .nav_img_right .nav-item.nav_item_last {
        position: absolute;
        right: 0;
    }
    header.main_header .theme_navigation {
        padding: 0.5rem 2rem;
    }
  header.main_header .dropdown:hover .dropdown-menu {
    transform: scale(1);
  }
  header.main_header .dropdown .dropdown-menu {
    transition: all 0.5s;
    overflow: hidden;
    transform-origin: top center;
    transform: scale(1, 0);
    display: block;
    min-width: 170px;
  }
  header.main_header .ph_notify_dropdown .dropdown .dropdown-menu {
    max-width: 360px;
    min-width: 350px;
    top: 50px;
  }
  .profile_dropdown .dropdown-menu {
    position: absolute;
    top: 54px;
    left: auto;
    padding: 0;
    border-radius: 0 0 8px 8px;
    border: 0;
  }
  header.main_header ul .nav-item.ims_admin .nav-link, header.main_header ul .nav-item.ims_location .nav-link{
    margin-left: 1.5rem;
    padding: 0 1rem;
    font-size: 14px;
  }
  .ims_mx30 {
      padding-left: 30px;
      padding-right: 30px;
  }
}
@media (min-width: 576px) {
    .ims_modal .modal-sm {
        max-width: 350px;
    }
}

@media screen and ( max-width: 1680px) {
    .home_hero {
        background-size: 82%;
    }
}
@media screen and ( max-width: 1500px) {
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 26px;
    }
    h4 {
        font-size: 19px;
        letter-spacing: 0.4px;
    }
    .theme_btn {
        padding: 8px 16px;
        font-size: 14px;
    }
    .ph_switch {
        width: 70px;
        height: 34px;
    }
    .ph_slider::before {
        height: 26px;
        width: 26px;
    }
}
@media screen and ( max-width: 1199px) {
    .container{
        max-width: 100%;
    }
    select.cstSelectW160 {
        background-position: 93.2% center;
    }
    input.search_inline {
        width: 300px;
    }
}
@media screen and ( max-width:991px) {
    header.main_header .theme_navigation {
        height: 4rem;
    }
    .theme_navigation .nav_both_wrp {
        align-items: center;
        background: #fff;
        display: none;
        flex-direction: column;
        height: 100vh;
        justify-content: start;
        left: 0;
        overflow: scroll;
        padding-top: 10vh;
        position: fixed;
        text-align: center;
        top: 0;
        touch-action: manipulation;
        width: 100%;
        z-index: 10;
    }
    .theme_navigation.-open .nav_both_wrp {
        display: flex;
        justify-content: start !important;
    }
    .theme_nav_mt {
        margin-top: 65px;
    }
    .theme_navigation .nav li {
        margin-bottom: 2rem;
    }
    header.main_header .avatar_dropdown .dropdown .dropdown-menu {
        min-width: 250px;
        top: 45px;
        left: calc(50% - 250px/2);
    }
    .theme_navigation .nav li .avatar_dropdown li{
        margin-bottom: 0;
    }
    .home_hero {
        padding: 60px 0 75px 0;
    }
    .home_hero .home_hero_inner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .ims_grid_table td {
        padding: 0.75rem;
    }
    h1 {
        font-size: 27px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 21px;
    }
    h4 {
        font-size: 18px;
    }
    h6 {
        font-size: 15px;
    }
    .ims_info .ims_info_val {
        font-size: 14px;
    }
    .ims_info .ims_info_title {
        font-size: 15px;
    }
    .ims_grid_table span.ims_info_val {
        min-width: 115px;
    }
    .home_hero {
        background-size: cover;
    }
    .ims_icn_box_card .ims_icn_box {
        width: 80px;
        height: 80px;
    }
    .ims_icn_box_card .ims_icn_box_cnt {
        width: calc(100% - 80px);
    }
    .ims_sec {
        padding: 20px 0 70px 0;
    }


    .wrap-login100.sp_btwn_wrp {
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .wrap-login100.sp_btwn_wrp .user_details_list_wrp {
        width: 100%;
        margin: 0 auto 30px auto;
    }
    .wrap-login100.sp_btwn_wrp .login100-form {
        width: 100%;
    }
    .wrap-login100.sp_btwn_wrp .login100-form .inner_form{
        max-width: 100%;

    }
}
@media (max-width: 991px) and (orientation: landscape) {
    .theme_navigation .nav>li {
        margin-bottom: 1rem;
    }
}
@media screen and ( max-width:767px) {
    .ims_filter_cont ul.nav-pills {
        flex-direction: column;
        margin-top: 15px;
    }
    .ims_filter_cont ul.nav-pills li {
        margin-left: 0;
        margin-bottom: 15px;
    }
    .ims_grid_table h4 {
        font-size: 14px;
    }
    .ims_grid_table h2 {
        font-size: 15px;
    }
    .ims_grid_table h6 {
        font-size: 13px;
    }
    .ims_grid_table .tooltip_btn {
        width: 25px;
        height: 25px;
    }
    .ims_grid_table .theme_btn {
        padding: 7px 14px;
        font-size: 12px;
    }
}
@media screen and ( max-width:575px) {
    .hero-marque {
        height: 50vh;
    }
    .hero-marque .text-holder h1{
        font-size: 30px;
    }
    .hero-marque .text-holder {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 17px;
    }
    .hero-marque{
        margin-bottom: 15px;
    }
    .wrap-login100 {
        width: 100%;
        padding: 35px 20px;
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .login100-form .theme_btn {
        min-width: 100%;
    }
    .login100-form .login100-form-title {
        font-size: 30px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 19px;
    }
    .ims_icn_box_card .ims_icn_box {
        width: 55px;
        height: 55px;
    }
    .ims_icn_box_card .ims_icn_box_cnt {
        width: calc(100% - 55px);
    }
    .img_header_toolbar a {
        padding: 0.8rem;
    }
    select.cstSelectW160 {
        background-position: 95.2% center;
    }
    input.search_inline {
        width: 100%;
        margin-left: 0;
        margin-top: 5px;
    }
    .search_wrp.ims_flex {
        flex-direction: column;
        margin-bottom: 16px;
    }
    .ims_table h5 {
        font-size: 14px;
    }

    .vertical_tabs_cont .vertical_tabs_colL {
        width: 100%;
        padding-right: 0;
    }
    .vertical_tabs_cont .vertical_tabs_colR {
        width: 100%;
        height: auto;
        padding-left: 0;
    }
    .vertical_tabs_cont .vertical_tabs_colL .tab_list_block {
        padding-top: 0;
    }
    .vertical_tabs_cont {
        padding-bottom: 35px;
    }
    .vertical_tabs_cont .tab_list_block .nav-pills .nav-link {
        margin-bottom: 8px;
        padding: 0.4rem 0 0.1rem 0;
        max-width: max-content;
    }
    .vertical_tabs_cont .tab_list_block {
        padding-bottom: 35px;
    }
    .vertical_tabs_cont .wrap-login100 {
        width: 100%;
    }
    .vertical_tabs_cont .tab_list_block {
        padding-top: 25px;
        position: relative;
    }
    .tab_list_block_767:after {
        height: 1px;
        content: '';
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 10px;
        background-color: $black;
    }
    .vertical_tabs_cont .limiter_heading_wrp h3 {
        font-size: 20px;
    }
    .vertical_tabs_cont .inner_form {
        padding: 29px 25px;
    }
    .wrap-login100.sp_btwn_wrp .login100-form {
        margin-bottom: 30px;
    }
    .overlay1 .big_art h3 {
        font-size: 39px;
    }
    .overlay1 .big_art p {
        font-size: 19px;
    }

    .order_details_sec .info_title {
        font-size: 14px;
    }
    .order_details_sec .info_val {
        font-size: 13px;
    }
    footer.main_footer .footer_link {
        font-size: 13px;
    }
}
/* // Media query */
