/**** Main header css here ****/
header.main_header{
    position: fixed;
    top:0;
    width: 100%;
    z-index: 999;
    transition: all .1s ease-in-out;
    background: $white;
    box-shadow: 0 13px 35px -12px rgba(35,35,35,.15);
    border-bottom: 1px solid $brand-tertiary;
    .theme_navigation  {
        padding: 0.5rem 1.5rem;
        height: 80px;
    }
    .logo {
        max-height: 100%;
    }
    ul{
        width: 100%;
        align-items: center;
        .nav-item{
            position: relative;
            &:hover {
                .nav-link:before {
                    opacity: 1;
                    width: 100%;
                }
                .nav-link {
                    color: $brand-primary;
                }
            }
            //.nav-link.active:before {
            //    opacity: 1;
            //    width: 100%;
            //}
            .nav-link{
                color: $brand-secondary;
                font-size: 15px;
                font-weight: $ft_Weight_SM;
                padding: 0;
                position: relative;
                text-transform: uppercase;
                //&:before {
                //    background: $brand-primary;
                //    border-radius: 2.5px;
                //    bottom: -3px;
                //    content: "";
                //    height: 3px;
                //    left: 0;
                //    opacity: 0;
                //    position: absolute;
                //    transition: all .3s ease-in-out;
                //    width: 20px;
                //    z-index: -1;
                //}
                &.active {
                    color: $brand-primary;
                }
                &.not_line:before {
                    display: none;
                }
                &.a-btn {
                    color: $brand-primary;
                    background-color: $white;
                    border-color: $brand-tertiary;
                    padding: 0 1.5rem;
                    line-height: 2.5;
                    border: 1px solid $brand-tertiary;
                    border-radius: 6px;
                    &:hover {
                        background-color: $brand-tertiary;
                        color: $white;
                    }
                }
                &.a-btn-primry-b {
                    color: $brand-tertiary;
                    background-color: $white;
                    border-color: $brand-primary;
                    padding: 0 1.5rem;
                    line-height: 2.5;
                    border: 1px solid $brand-primary;
                    border-radius: 6px;
                    &:hover {
                        background-color: $brand-primary;
                        color: $white;
                    }
                }
            }
        }
    }
}
.header__logo {
    height: 32px;
}
/** toggle nav **/
.-open .navigation-toggler .icon-bar:nth-child(2) {
    transform: rotate(45deg);
}
.-open .navigation-toggler .icon-bar:nth-child(3) {
    opacity: 0;
}
.-open .navigation-toggler .icon-bar:nth-child(4) {
    transform: rotate(-45deg);
}
.navigation-toggler {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    z-index: 100;
    .icon-bar {
        background: $brand-primary;
        border-radius: 1px;
        display: block;
        height: 2px;
        margin-bottom: 6px;
        transform-origin: 0;
        transition: transform .2s ease;
        width: 1.5rem;
    }
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        border: 0;
    }
}
/** toggle nav **/

/**** Main header css here ****/
.avatar_dropdown {
    padding: 0;
    list-style: none;
    margin: 0;
    .dropdown-menu {
      position: absolute;
      top: 74px;
      left: auto;
      min-width: 170px;
      padding: 0;
      border-radius: 0;
      -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.26);
      -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.26);
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.26);
      .list_item {
        &.active {
          a {
            color: $brand-secondary;
            border-bottom: 1px solid $brand-primary;
          }
        }
        a {
          color: $brand-secondary;
          padding: 10px 15px;
          display: block;
          font-size: 14px;
          text-decoration: none;
          &:hover {
            color: $brand-primary;
            font-weight: $ft_Weight_M;
          }
          &.active {
            color: $brand-primary;
            font-weight: $ft_Weight_M;
          }
        }
      }
    }
    .user_menu_dropdown {
      display: flex;
      align-items: center;
      &::after {
          display: none;
      }
    }
  }
.branch_id_dropdown {
  .dropdown-toggle.user_menu_dropdown {
    display: flex;
    align-items: center;
    &::after {
      width: 10px;
      height: 7px;
      background-size: 10px;
      background: transparent image_url('icn_dropdown_black.svg') no-repeat;
      border: 0;
      margin-top: 3px;
      margin-left: 8px;
      display: inline-block;
    }
  }
}
.theme_navigation {
  .nav_both_wrp {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}