body{
  font-size: $font-size-base;
  -webkit-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}
html, body {
  height: 100%;
}
#wrap {
  width: 100%;
}
a{
  -webkit-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
:focus,:active{
  outline: none;
}

.theme_nav_mt {
  margin-top: 80px;
}
.ims_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ims_flex_v {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ims_flex_btwn {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ims_card {
  box-shadow: 0px 0px 33px #16449A0D;
  border: 1px solid $gray_02;
  border-radius: 10px;
  margin-top: 25px;
  .card-footer {
    background-color: $white;
    border-top: 0;
    padding: 1.25rem;
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: calc(50% - 86%/2);
      right: 0;
      content: '';
      width: 86%;
      height: 1px;
      background: $gray_02;
    }
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(10px - 1px) calc(10px - 1px);
  }
  .ims_fig_mt {
    margin-top: 30px;
  }
}
.tooltip .tooltip-inner {
  max-width: 115px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  background-color: $white;
  border: 1px solid $gray-500;
  color: $brand-tertiary;
  font-size: 13.5px;
  line-height: 27px;
  box-shadow: 0px 3px 11px $gray_02;
  font-family: $font-family-base !important;
}
.tooltip .arrow::before, .tooltip .arrow::after {
  content: "";
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: 0;
  left: 0;
}
.tooltip .arrow::before {
  border-top: 8px solid $gray-500;
  margin-top: 0px;
}
.tooltip .arrow::after {
  border-top: 8px solid $white;
  margin-top: -1px;
  z-index: 1;
}
button:focus {
  outline: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-width: 0 8px 8px;
  border-bottom-color: $white;
}
.ims_body {
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-backdrop {
      background-color: $gray_025;
      background: $gray_025 0% 0% no-repeat padding-box;
      opacity: 1;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
  }
}