/*background:image_url("slider-arrow.png") no-repeat left top;
*/
.container{
  max-width: 1380px;
}
body.body_overflow {
  overflow: hidden;
}
/**** css here for without scroller ****/
.limiter {
  width: 100%;
  margin: 0 auto;
  .container-login100 {
    width: 100%;
    background-color: $white;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
}
/**** css here for without scroller ****/
.a-btn {
  border-radius: 10px;
  border: 0;
  color: $brand-primary;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  padding: 0 1.5rem;
  text-decoration: none;
  transition: background-color .2s ease;
  white-space: nowrap;
}
.home_hero {
  // background-image: image_url("hero.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 84%;
  background-attachment: fixed;
  min-height: 100vh;
  padding: 80px 0 60px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.ims_filter_cont {
  text-align: right;
  ul.nav-pills {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 12px;
    }
    .nav-link {
        font-size: 15px;
        padding: 10px 15px;
        border-radius: 5px;
        border: 1px solid $white;
        background-color: $white;
        color: $brand-secondary;
        min-width: 120px;
        display: block;
        text-align: center;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        transition: 0.4s all ease;
        -webkit-transition: 0.4s all ease;
        -moz-transition: 0.4s all ease;
        -o-transition: 0.4s all ease;
        position: relative;
        &.active, &:hover {
          background-color: $brand-primary;
          border-color: $brand-primary;
          color: $white;
          box-shadow: 0px 3px 11px #FE850033;
        }
      }
  }
  .profile_dropdown {
    text-align: right;
    li.dropdown {
      display: inline-block;
      .user_menu_dropdown {
        background-color: $white;
        border: 1px solid #7983974D;
        border-radius: 5px;
        width: 50px;
        height: 50px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
          justify-content: center;
        img {
          width: 24px;
        }
      }
      ul {
        .list_item {
          a {
            color: $gray-500;
          }
          &:hover {
            a {
              color: $brand-secondary;
              border-bottom: 0;
              background-color: #70707008;
              font-weight: 600;
            }
          }
          &.active {
            a {
              color: $brand-secondary;
              border-bottom: 0;
              background-color: #70707008;
              font-weight: 600;
            }
          }
        }
      }
    }
    .dropdown-menu {
      border-radius: 5px;
      -webkit-box-shadow: 0px 22px 32px #16449A33;
      box-shadow: 0px 22px 32px #16449A33;
    }
  }
}
.theme_badge {
  color: $black;
  width: 28px;
  height: 28px;
  line-height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -13px;
  border: 2px solid $white-100;
}
.badge_tertiary {
  background-color: $brand-tertiary;
  color: $white;
}
.theme_btn.grid_view_btn {
  margin-left: 12px;
  width: 36px;
  height: 36px;
  line-height: 31px;
  text-align: center;
  padding: 0;
  img {
    width: 17px;
  }
}
.ims_list {
  margin-bottom: 0;
  padding: 0;
}
.mx_eql {
  margin-top: 20px;
  margin-bottom: 20px;
}
.tooltip_btn {
  width: 22px;
  height: 22px;
  padding: 0;
  text-align: center;
  border-radius: 50%;
  line-height: 0;
  background-color: $gray-500;
  color: $white;
  outline: 0;
  border: 0;
  box-shadow: none;
  margin-left: 5px;
  webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  .fa{
    font-size: 15px;
}
}
.ims_modal {
  .ims_card {
    box-shadow: none;
    margin-top: 0;
  }
  .modal-header {
    padding-bottom: 0;
  }
  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
 .link_close {
    display: inline-block;
    margin-left: auto;
}
.modal-content {
  border-radius: 10px;
}
  .mnw_250 {
    min-width: 250px;
  }
}
.ims_info {
  margin: 15px 0;
  .ims_info_title {
    font-size: 16px;
    font-weight: $ft_Weight_SM;
    color: $brand-secondary;
  } 
  .ims_info_val {
    font-weight: $ft_Weight_SM;
    font-size: 15px;
}
}
.ims_time_info {
  margin-bottom: 0;
  .ims_info_title {
    display: block;
    margin-bottom: 5px;
  }
  .ims_info_val {
    background: $blue_03;
    border: 1px solid $gray_02;
    padding: 8px 15px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    display: block;
}
}
.tick_success {
  background-color: $gray-300;
  color: $brand-success;
  &:hover {
    color: $brand-success-hover;
  }
  img {
    margin-right: 8px;
    width: 19px;
  }
}
.ims_sec {
  padding: 30px 0 70px 0;
}
.ims_grid_view_inner {
  display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.ims_grid_table {
  color: $brand-secondary;
  margin-top: 25px;
  tr {
    background-color: $white;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 1px 2px rgba(112, 112, 112, 0.2) inset;
    box-shadow: inset 0px 0px 1px 2px rgba(112,112,112,0.2);
    -ms-box-shadow: inset 0px 0px 1px 2px rgba(112,112,112,0.2);
    -moz-box-shadow: inset 0px 0px 1px 2px rgba(112,112,112,0.2);
  &.ims_tr_gap {
      box-shadow: none;
    }
  }
  td {
    padding: 1.5rem;
    vertical-align: middle;
  }
  .mx_eql {
    margin-top: 5px;
    margin-bottom: 0;
  }
  span.ims_info_val {
    min-width: 150px;
    border-radius: 28px;
}
.cstmr_ar_para {
  margin-bottom: 5px;
}
h2 {
  font-size: 24px;
 }
 h4 {
  font-size: 17px;
}
}
@supports (-ms-ime-align: auto) {
  .ims_grid_table tr {
    -ms-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(112,112,112,0.2);
  }
}
.ims_icn_box_card {
  -o-transition: all .3s;
  transition: all .3s;
-webkit-transition: all .3s;
-moz-transition: .3s all;
-o-transition: .3s all;
&:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  -webkit-box-shadow: 0 0 11px rgba(33,33,33,.2);
  -moz-box-shadow: 0 0 11px rgba(33,33,33,.2);
}
  .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ims_icn_box_cnt {
    width: calc(100% - 94px);
    float: left;
  }
  .ims_icn_box {
    width: 94px;
    height: 94px;
    background-color: $brand-primary;
    border-radius: 100%;
    text-align: right;
    float: right;
    margin-left: 15px;
  }
}
.ims_fig {
  margin-bottom: 0;
}
.img_header_toolbar {
  background: $white;
  border: 1px solid $gray_05;
  border-radius: 5px;
  display: inline-block;
    a {
      display: inline-block;
      color: $brand-secondary;
      background-color: $white;
      padding: 0.7rem 1rem;
      &.active{
        background-color: $brand-tertiary;
        color: $white;
      }
    }
    a:nth-child(1) {
      border-radius: 5px 0 0 5px;
    }
    a:nth-last-child(1) {
      border-radius: 0 5px 5px 0;
    } 
    .ims_Vse {
      color: $gray_05;
    }
}
.ims_table {
  margin-top: 30px;
  thead {
    background-color: $tableHead;
    th {
      color: $brand-tertiary;
      font-size: 13px;
      font-weight: $ft_Weight_SM;
      padding: 0.75rem 0.95rem;
      .fa {
        margin-left: 5px;
      }
    }
  }
  tbody {
    td {
      font-size: 14px;
      color: $brand-secondary;
      padding: 22px 18px;
      border-bottom: 1px solid rgba(122, 133, 129, 0.2);
    }
    tr {
      &:hover {
        background-color: $white-600;
      }
    }
    .input_modify {
      height: 38px;
    }
  }
}
.ims_table th:first-child {
  border-left: 1px solid rgba(122, 133, 129, 0.2);
}
.ims_table th:last-child {
  border-right: 1px solid rgba(122, 133, 129, 0.2);
}
.ims_table td:first-child {
  border-left: 1px solid rgba(122, 133, 129, 0.2);
}
.ims_table td:last-child {
  border-right: 1px solid rgba(122, 133, 129, 0.2);
}
.ims_grid_table tr:nth-last-child(1) td:nth-last-child(1) {
  border-radius: 0 0 20px 0;
}
.home_hero_inner {
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
input.search_inline {
  width: 500px;
  margin-left: 15px;
  height: 50px;
}
.heading_with_badge {
  h1 {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 12px;
  }
  .badge_tertiary {
    position: inherit;
    margin: 0;
    display: inline-block;
    border-color: $white-100;
    -webkit-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
        transform: translateY(-6px);
  }
}

 
  td.ims_Lborder {
    padding: 0.75rem 1.5rem !important;
    position: relative;
    &.ims_Lborder:before {
      width: 10px;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 10px 0px 0px 10px;
    }
    &.Lborder_blue:before {
      background-color: #A020F0;
    }
    &.Lborder_red:before {
      background-color: #FF3E0C;
    }
    &.Lborder_warning:before {
      background-color: #FEDC00;
    }
    &.Lborder_succsess:before {
      background-color: #10CF69;
    }
  }
  .ims_table {
    .mx_eql {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 21px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
    }
    span.ims_info_val {
      width: 150px;


    }

  }
  .ims_drop_inner {
    .dropdown-toggle {
      color: $black;
      font-size: 13px;
      &::after {
        content: none;
      }
    }
    .fa-angle-up {
      display: none;
    }
    .fa {
      margin-left: 5px;
    }
    &.show {
      .fa-angle-down {
        display: none;
      }
      .fa-angle-up {
        display: inline-block;
      }
    }
    .dropdown-item {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: $black;
      padding: 0.25rem 1rem;
      img {
        width: 13px;
      }
      &:hover, &:focus {
        background-color: #DADDE142;
      }
    }
    .dropdown-menu {
      min-width: 100px;
      box-shadow: 0 2px 6px #2C28281C;
      border: 0;
    }
    &.vas_drop_menu {
      .dropdown-menu {
        min-width: 150px;
      }
      .dropdown-item {
        padding: 0.55rem 1rem;
      }
      .theme_check_cont {
        padding-left: 0;
        margin-bottom: 0;
        font-size: 12px;
        display: block;
        width: 100%;
      }
      .theme_checkmark {
        right: 0;
        left: auto;
        height: 17px;
        width: 17px;
      }
      .theme_check_cont .theme_checkmark:after {
        left: 3px;
        top: 4px;
        width: 8px;
        height: 6px;
        background-size: 100%;
      }
      .dropdown-toggle {
        img {
          width: 18px;
          margin-top: -2px;
        }
      }
    }
  }
  .icn_w20 {
    height: 20px;
    display: inline-block;
    img {
      height: 100%;
      width: auto;
    }
}

.vertical_tabs_cont {
  display: table;
  max-width: 1016px;
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 139px);
  position: relative;
  padding: 50px 0;
  .tab_list_block {
    padding-top: 0;
    padding-bottom: 100px;
    .empty_img_wrp {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      img {
        width: 200px;
      }
    }
    .nav-pills {
      .nav-link {
        color: $brand-secondary;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        padding: 0;
        border-bottom: 1px solid $brand-secondary;
        border-radius: 0;
        max-width: max-content;
        &.active{
          color: $brand-primary;
          background-color: transparent;
          border-bottom-color: $brand-primary;
          border-radius: 0;
        }
      }
    }
  }
  .vertical_tabs_col {
    float: left;
  }
  .vertical_tabs_colL {
    width: 215px;
    padding-right: 30px;
    .tab_list_block {
      padding-top: 45px;
    }
  }
  .vertical_tabs_colR {
    width: calc(100% - 215px);
    height: 100%;
    padding-left: 35px;
  }
  .icn_cal_wrp .icn_cal {
    top: 47px;
  }
  .wrap-login100 {
    width: 100%;
    background: transparent;
    border-radius: 3px;
    margin-bottom: 40px;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: 0;
    overflow: visible;
  }
  .limiter_heading_wrp {
    h3 {
      font-size: 25px;
      text-align: center;
      color: $brand-secondary;
      font-weight: 600;
    }
  }

  .inner_form {
    width: 100%;
    max-width: 500px;
    margin: 30px auto 0;
    background-color: $white;
    padding: 30px 35px;
    border-radius: 10px;
    border: 1px solid rgba(112, 112, 112, 0.2);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    &.mxW_670 {
      max-width: 670px;
    }
    h5 {
      margin-bottom: 15px;
    }
    .fields {
      margin-bottom: 15px;
    }
    .btn_lg {
      min-width: 183px;
      margin-bottom: 30px;
      font-size: 20px;
      padding: 18px 38px;
      font-weight: 400;
    }
  }
}
.ph_flex_wrp_spw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ph_switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 44px;
}
.ph_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
input:checked + .ph_slider {
  background-color: $brand-primary;
}
.ph_slider.round {
  border-radius: 34px;
}
.ph_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #C1C1C1;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .ph_slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}
.ph_slider.round:before {
  border-radius: 50%;
}
.ph_slider:before {
  position: absolute;
  content: "";
  height: 36px;
  width: 36px;
  left: 4px;
  bottom: 4px;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
}
.ph_switch_wrp.switch_wrp .ph_switch_text {
  padding-right: 10px;
}
.wrap-login100.sp_btwn_wrp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
          .user_details_list_wrp {
            width: 48%;
        }
        .login100-form {
          width: 48%;
        }
        ul.list-group {
          border-radius: 10px;
          margin-top: 20px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        p.info_title {
          font-weight: 600;
          margin-bottom: 10px;
          font-size: 14px;
          &:last-child {

            margin-bottom: 0;
          }
      }
      }
}

.ims_hide_wrp {
  display: none;
}
.icons_wrp_abslt {
  position: absolute;
  top: 8px;
  right: 20px;
  a {
    margin-left: 5px;
    font-size: 18px;
    color: $brand-tertiary;
    &:hover, &:focus {
      color: $brand-tertiary-hover;
    }
  }
}
/* coming soon css page here */

.overlay1 {
  position: relative;
  z-index: 1;
  height: 100%;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
  }
}
.big_art {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 15px 0 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  h3 {
    font-size: 50px;
    line-height: 1.1;
    color: $white;
    text-transform: uppercase;
  }
  p {
    font-size: 25px;
    line-height: 1.2;
    color: $white;
    margin-bottom: 0;
  }
}
.bg-img1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
}
/* coming soon css page here */
.order_details_sec {  
  padding: 25px 0 55px;

  .ims_art {
    h3 {
      margin-bottom: 18px;
    }
  }
  .info_title {
    font-weight: 600;
    color: #233047;
    margin-bottom: 3px;
  }
  .info_val {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .order_details_list{
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 15px #16449A33;
    box-shadow: 0px 3px 15px #16449A33;
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding: 15px;
    margin-bottom: 20px;
    &.order_details_list1 {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      .info_val {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .info_val_dot {
        position: relative;
        padding-left: 15px;
        &::before {
          width: 10px;
          height: 10px;
          content: '';
          position: absolute;
          left: 0;
          top: 5px;
          border-radius: 50%;
        }
        &.info_suc {
          &::before {
            background-color: green;
          }
        }
        &.info_danger {
          &::before {
            background-color: red;
          }
        }
      }
    }
    &.order_details_list_heading {
      display: block;
      .info_val {
        margin-bottom: 8px;
        font-size: 15px;
      }
      .order_items {
        margin-bottom: 0;
        .info_val {
          margin-bottom: 0;
        }
      }
      .order_listL {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
      li {
        margin-bottom:  20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    li {
      border: 0;
      list-style: none;
    }
  }
  .order_items {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/** Socket disconnected banner css **/
.Socket_disconnect_banner {
  width: 100%;
  display: block;
  background-color: #FF3E0C;
  padding: 20px 15px;
  text-align: center;
  color: #fff;
  height: 100%;
  h3{
    line-height: normal;
    margin: 0;
  }
}

/** // Socket disconnected banner css **/