@mixin btn-style($border-radius) {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 18px;
  border-radius: $border-radius;
  cursor: pointer;
  // effect btn
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  letter-spacing: 0.36px;
  @include transition (all, 0.4s, ease-in-out);
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: radial-gradient(circle, $white 10%, transparent 10.01%) no-repeat 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }
  &:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }
  &:focus, &:active {
    box-shadow: none;
    outline: 0;
  }
}
@mixin btn-color-style($color,$background-color,$border-color) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;
}

.theme_btn {
  @include btn-style(5px);
  &.border_black {
    border-color: $black;
  }
  &.theme_btn_rdus33 {
    border-radius: 33px;
  }
}
.theme_btn_rdus {
  @include btn-style(33px);
}
.theme_primary {
  @include btn-color-style($white, $brand-primary, $brand-primary);
}
.theme_primary:hover, .theme_primary:focus{
  @include btn-color-style($white, $brand-primary-hover, $brand-primary-hover);
}
.theme_secondary {
  @include btn-color-style($white, $black, $black);
}
.theme_secondary:hover, .theme_secondary:focus{
  @include btn-color-style($white, $brand-secondary-hover, $brand-secondary-hover);
}
.theme_tertiary {
  @include btn-color-style($white, $brand-tertiary, $brand-tertiary);
}
.theme_tertiary:hover, .theme_secondary:focus{
  @include btn-color-style($white, $brand-tertiary-hover, $brand-tertiary-hover);
}
.theme_success {
  @include btn-color-style($white, $brand-success, $brand-success);
}
.theme_success:hover, .theme_success:focus{
  @include btn-color-style($white, $brand-success-hover, $brand-success-hover);
}
.theme_danger {
  @include btn-color-style($white, $brand-danger, $brand-danger);
}
.theme_danger:hover, .theme_danger:focus{
  @include btn-color-style($white, $brand-danger-hover, $brand-danger-hover);
}
.theme_warning {
  @include btn-color-style(#212529, #ffc107, #ffc107);
}
.theme_warning:hover, .theme_warning:focus{
  @include btn-color-style(#212529, #e0a800, #d39e00);
}
.theme_info {
  @include btn-color-style($white, #17a2b8, #17a2b8);
}
.theme_info:hover, .theme_info:focus{
  @include btn-color-style($white, #138496, #117a8b);
}
.theme_light {
  @include btn-color-style(#212529, #f8f9fa, #f8f9fa);
}
.theme_light:hover, .theme_light:focus{
  @include btn-color-style(#212529, #e2e6ea, #dae0e5);
}
.theme_dark {
  @include btn-color-style($white, #343a40, #343a40);
}
.theme_dark:hover, .theme_dark:focus{
  @include btn-color-style(#212529, #e2e6ea, #dae0e5);
}
.theme_btn_rds30 {
  border-radius: 30px;
}

.theme_link {
  font-weight: 400;
  color: $brand-primary;
  background-color: transparent;
}
.theme_link:hover {
  color: $brand-primary;
  background-color: transparent;
  border-color: transparent
}

/*outline btn css*/
.theme_outline_primary {
  @include btn-color-style($brand-primary, transparent, $brand-primary);
  background-image: none;
}
.theme_outline_primary:hover {
  @include btn-color-style($white, $brand-primary, $brand-primary);
}
.theme_outline_secondary {
  @include btn-color-style($brand-secondary, transparent, $brand-secondary);
  background-image: none;
}
.theme_outline_secondary:hover {
  @include btn-color-style($white, $brand-secondary-hover, $brand-secondary-hover);
}
.theme_outline_success {
  @include btn-color-style($brand-success, transparent, $brand-success);
  background-image: none;
}
.theme_outline_success:hover {
  @include btn-color-style($white, $brand-success-hover, $brand-success-hover);
}
.theme_outline_danger {
  @include btn-color-style(#dc3545, transparent, #dc3545);
  background-image: none;
}
.theme_outline_danger:hover {
  @include btn-color-style($white, #dc3545, #dc3545);
}
.theme_outline_warning {
  color: #ffc107;
  background: transparent none;
  border-color: #ffc107;
}
.theme_outline_warning:hover {
  @include btn-color-style(#212529, #ffc107, #ffc107);
}
.theme_outline_info {
  @include btn-color-style(#17a2b8, transparent, #17a2b8);
  background-image: none;
}
.theme_outline_info:hover {
  @include btn-color-style($white, #17a2b8, #17a2b8);
}
.theme_outline_light {
  color: #f8f9fa;
  background: transparent none;
  border-color: #f8f9fa
}
.theme_outline_light:hover {
  @include btn-color-style(#212529, #f8f9fa, #f8f9fa);
}
.theme_outline_dark {
  @include btn-color-style(#343a40, transparent, #343a40);
}
.theme_outline_dark:hover {
  @include btn-color-style($white, #343a40, #343a40);
}
/* // outline btn css*/
.theme_btn_lg {
  font-weight: $ft_Weight_SM;
  font-size: 18px;
  padding: 7px 24px;
}
.theme_btn_sm {
  font-size: 12px;
  padding: 4px 10px;
  font-weight: 400;
}
%primary_shad{
  box-shadow: -3px 0 22px 0 rgba(29,73,149,0.30);
  -webkit-box-shadow: -3px 0 22px 0 rgba(29,73,149,0.30);
  -moz-box-shadow: -3px 0 22px 0 rgba(29,73,149,0.30);
}
.btn_primary_shad {
  @extend %primary_shad;
}



