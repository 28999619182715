.pagination {
  .page-item {
    .page-link {
      border-color:#16449A;
      color: #16449A;
    }
  }
  .page-item.active .page-link{
    background-color: #16449A;
    border-color:#16449A;
    color:#fff;
  }
}