.loadingBtn {
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
    opacity: 0.6;
}

.transparent_bg_loading_btn{
  background-color: transparent !important;
}

.loadingBtn.transparent_bg_loading_btn.delete_icon_loader{
  color: #FE8500 !important;
  padding: 0 !important;
  font-size: 20px !important;
}

.ph_sm_cricle.notification_setting{
  position: relative;
}