// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$font-family-base: 'Open Sans', sans-serif;
$font-size-base:   0.865rem !important; // Assumes the browser default, typically `16px`
// Font Weights
$ft_Weight_100: 100;
$ft_Weight_200: 200;
$ft_Weight_300: 300;
$regular: 400;
$ft_Weight_M: 500;
$ft_Weight_SM: 600;
$bold: bold;

body{
  font-size: $font-size-base;
  transition: all .1s ease-in-out;
  color: $brand-secondary;
  font-family: $font-family-base !important;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.3px;
  background-color: $white;
}

// Font Size Define
.display-1 {
  font-size: 60px;
  font-weight: 400;
}
.display-2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: $bold;
}
$-h1: 34px;
$-h2: 30px;
$-h3: 25px;
$-h4: 20px;
$-h5: 18px;
$-h6: 16px;
$para-font: 15px;
$border-rad5: 5px;

h1 {
  font-size: $-h1;
  letter-spacing: 0.72px;
}
h2 {
  font-size: $-h2;
}
h3 {
  font-size: $-h3;
}
h4 {
  font-size: $-h4;
  letter-spacing: 0.4px;
}
h5 {
  font-size: $-h5;
}
h6 {
  font-size: $-h6;
}
p {
  font-size: $para-font;
}
.fontS13 {
  font-size: 13px !important;
}
.fontS14 {
  font-size: 14px !important;
}

.ft_Weight_100 {
  font-weight: $ft_Weight_100 !important;
}
.ft_Weight_200 {
  font-weight: $ft_Weight_200 !important;
}
.ft_Weight_300 {
  font-weight: $ft_Weight_300 !important;
}
.ft_Weight_400 {
  font-weight: $regular !important;
}
.ft_Weight_500 {
  font-weight: $ft_Weight_M !important;
}
.ft_Weight_600 {
  font-weight: $ft_Weight_SM !important;
}
.ft_Weight_700 {
  font-weight: $bold !important;
}
.fontS13 {
  font-size: 13px !important;
}
.fontS17 {
  font-size: 17px !important;
}
.text-tertiary {
  color: $brand-tertiary !important;
}
.text-graylight {
  color: $gray-500;
}
.text-graydark1 {
  color: $input-text;
}
// Montserrat Fonts

@font-face {
  font-family: "Open Sans";
  src: url(font-path("OpenSans/OpenSans-light/OpenSans-Light.woff2")) format("woff2"),
  url(font-path("OpenSans/OpenSans-light/OpenSans-Light.woff")) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src:url(font-path("OpenSans/OpenSans-regular/OpenSans-Regular.woff2")) format("woff2"),
  url(font-path("OpenSans/OpenSans-regular/OpenSans-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src:url(font-path("OpenSans/OpenSans-SemiBold/OpenSans-SemiBold.woff2")) format("woff2"),
  url(font-path("OpenSans/OpenSans-SemiBold/OpenSans-SemiBold.woff")) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src:url(font-path("OpenSans/OpenSans-bold/OpenSans-Bold.woff2")) format("woff2"),
  url(font-path("OpenSans/OpenSans-bold/OpenSans-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src:url(font-path("OpenSans/OpenSans-ExtraBold/OpenSans-ExtraBold.woff2")) format("woff2"),
  url(font-path("OpenSans/OpenSans-ExtraBold/OpenSans-ExtraBold.woff")) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}





